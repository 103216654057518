import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../redux/store';
import * as courseSelectors from '../../redux/selectors/course';
import { actions as authActions } from '../../redux/modules/auth';
import config from '../../config';
import history from '../../history';
import sidebarLinks from '../../shared/SideBar/links';
import Mentroship from '../Mentorship/Mentorship';
import SearchQuestionModal from '../SearchQuestion/SearchQuestionModal';
import { Container, Item } from './styles';

type TProps = {
  logout: () => {};
  cursos: any;
};

const getAuthorizationAdminLink = () => {
  const state = store.getState();
  const token = state.auth && state.auth.token;
  window.location.href = `${config.REACT_APP_URL}/login?act=${token}`;
};

function Navigation({ logout, cursos }: TProps) {
  const [showMentorshipModal, setMentorshipModal] = useState(false);
  const [showSearchQuestionModal, setSearchQuestionModal] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const renderLink = (item: any) => {
    if (item.openURL)
      return (
        <a href={item.openURL} rel="noopener noreferrer" target="_blank">
          {item.title}
          {!!item.totalNotifications && <strong>{item.totalNotifications}</strong>}
        </a>
      );

    if (item.link) {
      if (item.name === 'start') {
        return (
          <Link to={`/`}>
            {item.title} {!!item.totalNotifications && <strong>{item.totalNotifications}</strong>}
          </Link>
        );
      } else {
        return (
          <Link to={`/${item.name}`}>
            {item.title} {!!item.totalNotifications && <strong>{item.totalNotifications}</strong>}
          </Link>
        );
      }
    }
    if (item.tag) {
      return (
        <span>
          {item.title} {!!item.totalNotifications && <strong>{item.totalNotifications}</strong>}
        </span>
      );
    }

    return (
      <span>
        {item.title} {!!item.totalNotifications && <strong>{item.totalNotifications}</strong>}
      </span>
    );
  };

  function handleOpenSearchQuestionModal() {
    setSearchQuestionModal(true);
    history.push('?questoes=true');
  }

  const onClick = (item: any) => {
    switch (item.name) {
      case 'mentorship':
        setMentorshipModal(true);
        break;
      case 'questions':
        handleOpenSearchQuestionModal();
        break;
      case 'oldStudent':
        getAuthorizationAdminLink();
        break;
      case 'logout':
        logout();
        break;
      default:
        break;
    }
  };

  const renderSideBarMenu = () => {
    return sidebarLinks(cursos)
      .filter((item) => {
        let envHiddenMenu = false;
        try {
          envHiddenMenu = config.hiddenMenu.includes(item.name);
        } catch {
          // Any exception i gonna ignore, cause probably the environment variable was passed incorrect
        }

        return (typeof item.show === 'undefined' || item?.show) && !envHiddenMenu;
      })
      .map((item) => (
        <Item
          className={mobileOpen ? 'open' : ''}
          key={item.name}
          id={item.name}
          border={!!item.border}
          onClick={() => onClick(item)}
        >
          {renderLink(item)}
        </Item>
      ));
  };

  const handleOpenMobile = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Container>
      {renderSideBarMenu()}
      <Item key="more" id="more" onClick={handleOpenMobile}>
        <span>Mais itens</span>
      </Item>
      {showMentorshipModal && <Mentroship setMentorshipModal={setMentorshipModal} />}
      {showSearchQuestionModal && (
        <SearchQuestionModal setShowSearchQuestionModal={setSearchQuestionModal} />
      )}
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  cursos: courseSelectors.getEnrollmentsList(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(authActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
