import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// import { debugContextDevtool } from 'react-context-devtool'
import registerServiceWorker from './registerServiceWorker'
import App from './App'

Sentry.init({
  dsn: "https://685e8df5e5ec484485b787f3dbb24100@o573353.ingest.sentry.io/5723812",
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.3,
})

const container = document.getElementById('root')

ReactDOM.render(<App />, container)

registerServiceWorker()