import React from 'react'

import { Form as AntForm, Input } from 'antd'

import PropTypes from 'prop-types'

const propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
}

function TextField({
  label,
  name,
  value = '',
  errorMessage,
  onChange,
  readOnly = false,
  disabled = false,
  dataTestId = '',
}) {
  return (
    <AntForm.Item label={label} validateStatus={errorMessage ? 'error' : ''} help={errorMessage}>
      <Input
        data-testid={dataTestId}
        name={name}
        onChange={onChange}
        readOnly={readOnly}
        value={value}
        disabled={disabled}
      />
    </AntForm.Item>
  )
}

TextField.propTypes = propTypes

export default TextField
