import config from '../config'
import store, { persistor } from '../redux/store'
import { JSEncrypt } from 'jsencrypt';
import { rsaPub } from './contants';

const { API_BASE } = config

//eslint-disable-next-line
const queryString = require('query-string')
// const { actions } = require('../redux/modules/account')

const getAuthorization = () => {
  const state = store.getState()
  const tokenStore = state.auth && state.auth.token
  const tokenPersist = JSON.parse(JSON.parse(localStorage.getItem('persist:root') || '{}')?.auth || '{}')?.token 
  const token = tokenStore || tokenPersist
  return token ? `Bearer ${token}` : void 0
}

// const isLoggedIn = () => {
//   const state = store.getState()
//   return !!(state.account && state.account.user)
// }

// const isLiteralObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]'

const jsonBody = (response) => {
  try {
    return response.json()
  } catch (err) {
    console.warn('The server did not send a JSON response', err)
    return {}
  }
}


//
// TODO: change to axios
const request = async (path, options, optionalsHeaders) => {
  options = Object.assign({}, options, {
    headers: {
      Authorization: getAuthorization(),
      ...optionalsHeaders
    },
  })
  const { body, qsParams } = options
  options.body = body
  options.headers = Object.assign(options.headers || {}, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  })
  options.mode = 'cors'
  const qs = qsParams ? `?${queryString.stringify(qsParams)}` : ''
  const response = await fetch(`${API_BASE}${path}${qs}`, options)
  const json = await jsonBody(response)
  const createError = () => {
    //
    // login errors
    if (path === 'login') {
      return new Error(json.message)
    }

    return new Error(json.error || response.statusText || 'Unexpected error')
  }

  if ([400, 401, 403].includes(response.status)) {
    persistor.purge();
    window.location.href = '/auth/login?unauthorized';
  }

  if (!response.ok) {
    throw new Error(createError())
  }
  return json
}

export const makeURLToken = () => {
    const crypt = new JSEncrypt();
    crypt.setPublicKey(rsaPub);

    return crypt.encrypt(config.apiKey);
};

export default request
