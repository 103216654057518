import React, { useEffect } from 'react';
import LogoIcon from '../../../../../img/svg/logo-icon.svg'
import Logo from '../../../../../img/svg/logo-white.svg'
import LogoGold from '../../../../../img/logo-gold.svg'

import {
  Container,
  Header,
  TextHeader,
  Img,
  NameRow,
  ImgLogo,
  Description,
  TitleModule,
  FooterReport,
} from './styles'

import { useDispatch, useSelector } from 'react-redux'
import { actions as studentActions } from '../../../../../redux/modules/student';
import { Col } from 'antd';
import BarchartH from '../../../Report/Charts/BarChartH/BarChartH';
import BartChartV from '../../../Report/Charts/BartChartV/BartChartV';
import { Content } from '../../../styles';
import LegendChart from '../../../Report/Charts/SubtitleChart';
import ReportBreakPage from '../../../Report/ReportBreakPage';


// ReportText
import ReportText from '../../../Report/TextDescription';

import {
  getDefaultParams,
  getSusgestionByScoreToro,
  TYPE_COURSE_CATEGORY_CFP,
  TYPE_SCORE_INFORMATION_REPORT_WEEKLY,
  TYPE_SCORE_INFORMATION_REPORT_PDF
} from '../../../common';

import { isEmpty, isNaN } from 'lodash';
import Score from '../../PNG/Score';
import { fetchGeneralText } from '../../../../../redux/api/class';

function CfpReport() {
  const [textData, setTextData] = React.useState("");
  const { reportSimulado: report, scoreToroInformation } = useSelector((state) => state.student);
  const suggestion = getSusgestionByScoreToro(scoreToroInformation, Number(((report?.score?.score ?? 0) * 100).toFixed(1)))

  const dispatch = useDispatch();
  useEffect(() => {
    const { userId, courseId, classId, media, start, end, kind, classes } = getDefaultParams(window.location.search);

    dispatch(studentActions.getScoreToroSugestionsByTypes(
      TYPE_COURSE_CATEGORY_CFP,
      TYPE_SCORE_INFORMATION_REPORT_PDF,
      TYPE_SCORE_INFORMATION_REPORT_WEEKLY
    ));

    dispatch(studentActions.handleCfpSimulado(
      userId,
      courseId,
      classId,
      media,
      start,
      end,
      kind,
      classes
    ));
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    (async ()=>{
      const filters = {
        typeReportId: TYPE_COURSE_CATEGORY_CFP,
        categoryReportId: TYPE_SCORE_INFORMATION_REPORT_WEEKLY
      }
      const textDataApi = await fetchGeneralText(filters)
      if(textDataApi[0]?.content !== undefined){
        setTextData(textDataApi[0]?.content)
      }
    })()
  },[])



  const reportPerClass = report?.performance_per_class;
  const totalAverage = (report?.average?.acertos_avg * 100).toFixed(1);
  // const totalScore = (report?.score?.score * 100).toFixed(1);
  const modulePerfomance = report?.module_performance;

  const improve = report?.you_can_improve_module;
  const reportDone = report?.test?.done ?? [];
  const reportExists = !isEmpty(reportDone);
  const modules = 'M1, M2, M3, M4, M5 e M6';

  const textoInicial = textData?.split("##imagem_relatório##")[0] || ""
  const dataGrafico = textData?.split("##imagem_relatório##")[1]?.split("##data_imagem:")[1]?.split("##")[0] || ""
  const textoFinal = textData?.split("##")[textData?.split("##")?.length-1] || ""


  const reportProvaResult =
    report !== undefined
      && reportExists
      && !isNaN(Math.round(reportDone[0]?.prova)) ? Math.round(report?.test?.done[0]?.prova) : 0;

if(modulePerfomance) {
  const allModules = ['M1', 'M2', 'M3', 'M4', 'M5', 'M6'];
  allModules.forEach(moduleName => {
    if(!modulePerfomance) return;
    if(!modulePerfomance.find(module => moduleName === module?.module)) {
      improve.push({module: moduleName, total: 0, tipo_prova: 'AULA'});
    }
});

  improve.sort(function (a, b) {
    if (a.module > b.module) {
      return 1;
    }
    if (a.module < b.module) {
      return -1;
    }
    return 0;
  });
}

  const improveMessage = (improve = []) => {
    if (isEmpty(improve)) return '';
    const total = (improve ?? []).length - 1;
    return improve.map((item, i) => {
      return (i > 0 && i < total ? ', ' : (i === total ? ' e ' : '')) + (item.tipo_prova === 'AULA' ? item.module : '');
    });
  };

  const recomendedPerformance = (modulePerfomance = false) => {
    if(!modulePerfomance) return false;

    let valid = [];
    const recomendedGoals = [85, 75, 85, 85, 80, 75];
    modulePerfomance.forEach((module, i) => {
      if ((module?.total * 100) < recomendedGoals[i]) {
        valid.push(i);
      }
    });
    return valid;
  }

  return (
    <>
      <Header>
        <Img src={Logo} alt="logo" />
      </Header>

      <Container>
        <NameRow justify="space-between">
          <Col>
            <h1>Olá {report?.name} :)</h1>
            <p>Este é o seu desempenho na primeira etapa do CFP®60 dias.</p>
          </Col>
          <Col>
            <ImgLogo src={LogoIcon} alt="logo" />
          </Col>
        </NameRow>

        <TextHeader>
          <div dangerouslySetInnerHTML={{ __html: textoInicial }} />
        </TextHeader>
        <Content>
          {
            typeof reportProvaResult === 'number' && (
              <TextHeader style={{ width: '100%', textAlign: 'center' }}>Você completou os exercícios de <strong>
                {reportProvaResult} provas</strong> das <strong> 30 disponíveis.</strong>
              </TextHeader>
            )
          }

          {
            typeof reportProvaResult === 'number' && (
              <BarchartH
                zIndex={1}
                displaySubtitle={'none'}
                displayAvaliable={"flex"}
                classNumber={reportProvaResult}
                percent={Number(((reportProvaResult / 30) * 100).toFixed(1))}
                percentText={`${reportProvaResult}`}
                colorbk1={'#000A66'}
                total={30}
                textAvaliableDate={`* ${dataGrafico.replace("<p>", "").replace("</p>", "")}`}
              />
            )
          }
        </Content>

        <Description style={{ marginBottom: '2.816vw' }}>
          <div dangerouslySetInnerHTML={{ __html: textoFinal }} />
        </Description>

        <ReportBreakPage />

        <ReportText type='graph'>Desempenho geral</ReportText>

        <div style={{ marginBottom: '3vw', width: '100%' }}>
          <Score />
        </div>

        <ReportText type='description'>
          <p>A prova do CFP® é composta por 140 questões divididas em 6 módulos.
            <br /> Você precisa atingir 70% no desempenho geral (98 acertos) e não pode acertar menos que 50% em nenhum dos módulos. Nosso calendário converge para que você veja a prova como um todo e, desta forma, sua chance de aprovação aumenta.</p>
          <p>Este gráfico representa seu Score Toro com os acertos de todos os exercícios que você resolveu na aulas na plataforma, desde aquela primeira prova de 20 perguntas até a última revisão.</p>
        </ReportText>

        {/* {
          ((totalScore ?? 0) >= 80 &&
            <ReportText type='description'>
              <p><span> No momento, você está acima de 80% no SCORE TORO, o que é um indicador muito favorável, tendo em vista isso, sugerimos manter o foco e continuar seguindo o cronograma de estudos.</span></p>
            </ReportText>
          )
        }
        {
          ((totalScore ?? 0) >= 70 && (totalScore ?? 0) <= 79 &&
            <ReportText type='description'>
              <p><span>No momento, você está acima de 70% no SCORE TORO, o que é um indicador favorável, tendo em vista isso, sugerimos manter o foco e continuar seguindo o cronograma de estudos e adicionar 25 questões extras diariamente.</span></p>
            </ReportText>
          )
        }
        {
          ((totalScore ?? 0) >= 50 && (totalScore ?? 0) <= 69 &&
            <ReportText type='description'>
              <p><span>No momento, você está quase chegando em 70% no SCORE TORO, o que é um indicador favorável, tendo em vista isso, sugerimos manter o foco e continuar seguindo o cronograma de estudos e adicionar 50 questões extras diariamente.</span></p>
            </ReportText>
          )
        }
        {
          (((totalScore ?? 0) <= 49 || totalScore.length === 3)  &&
            <ReportText type='description'>
              <p><span>No momento, você está abaixo de 50% de acertos na SCORE TORO, o que é um indicador não muito favorável, tendo em vista isso, sugerimos a migração para o <strong className='strong-span'>CFP® 30 DIAS</strong>, onde você poderá rever as aulas e assimilar melhor o conteúdo.</span></p>
            </ReportText>
          )
        } */}

        <ReportBreakPage />

        <ReportText type="graph">Desempenho por módulo</ReportText>
        <BartChartV
          linePercent={30}
          linePercentTwo={70}
          displaySubtitle={'flex'}
          subtitleMin={"seu desempenho"}
          subtitleRecommended={"recomendado"}
          positionSubtitle={'end'}
        />

        <ReportText type='description' style={{ marginTop: '3vw' }}>
          <p>Desta maneira, considerando os pontos que relatamos acima, orientamos que

            {/* ANCHOR */}
            {
              recomendedPerformance(modulePerfomance).length > 0  || totalAverage.length === 3
              ?
                <span className='strong-span'> você dê atenção ao(s) módulo(s) {totalAverage.length === 3 ? modules : improveMessage(improve)},
                que é(são) o(s) que está(ão) abaixo do recomendado pela Academia
                </span>
              :
              <span className='strong-span'> siga o cronograma normalmente, pois você está acima da média esperada em todos os módulos</span>
            }

            . Eles possuem alta relevância na sua prova. Você fará isso utilizando nossas aulas resumos, aumentando a carga de exercícios diários e quando possível a repetição deles. Além disso, ouvir os áudios destes módulos é fundamental.</p>
          <p>O Exame do CFP® divide-se em 6 módulos que totalizam 140 questões. Em seguida, vamos te contar um pouco sobre cada um deles e a importância para sua aprovação. Para isso, dividimos os tópicos dos módulos 1 e 2 devido a extensão de cada um deles. Já nos módulos 3, 4, 5 e 6, por se tratar de conteúdos únicos, comentaremos como a prova tem cobrado.</p>
        </ReportText>

        <ReportBreakPage />

        <TitleModule>Módulo 1 – Planejamento Financeiro e Ética</TitleModule>
        <ReportText type='description'>
          <p>É considerado o módulo mais importante da PROVA, podendo conter entre 53 e 64 questões. Acreditamos que sua prova terá 54 (cinquenta e quatro) questões (praticamente a mesma quantidade de questões da turno da tarde). Historicamente, a prova teve este equilíbrio de questões por tópico:</p>
        </ReportText>

        <ReportText type='description'>
          <p>
            <strong style={{ display: 'flex', width: '80vw', justifyContent: 'space-between' }}>
              <div>
                <div> 1. Etapas do Planejamento Financeiro: 4</div>
                <div> 2. Código de Ética da Planejar: 7</div>
                <div> 3. Competências do Planejador Financeiro: 4</div>
                <div> 4. Gestão Financeira: 7</div>
                <div> 5. Fundamentos de Economia: 8</div>
              </div>
              <div>
                <div>6. Fundamentos de Finanças: 7 </div>
                <div>7. Análise de Projetos: 6 </div>
                <div>8. Normas e Regulação: 3 </div>
                <div>9. Planejamento Financeiro: 8</div>
              </div>
            </strong>
          </p>
        </ReportText>

        <ReportText type='description'>
          <p>Portanto, queremos que você perceba a relevância dos conteúdos teórico-prático, comparado com os conteúdos diretamente de cálculos (apenas 30% deste módulo realmente necessitará o uso de uma HP-12C).</p>
          <p><strong>Recomendação: 85% (representa 46 acertos de 54 questões nas bíblias/simulado)</strong></p>
        </ReportText>

        <TitleModule>Módulo 2 – Gestão de Carteiras e Investimentos</TitleModule>
        <ReportText type='description'>
          <p>É o módulo com a maior quantidade de conteúdos (o dobro de conteúdos que o módulo 1), mas podendo conter apenas entre 21 a 27 questões. Acreditamos que sua prova terá 26. Historicamente, a prova teve este equilíbrio de questões por tópico:
            <ul>
              <li>1. Renda Fixa: 5</li>
              <li>2. Renda Variável: 3</li>
              <li>3. Derivativos: 2</li>
              <li>4. Fundos de Investimentos: 2</li>
              <li>5. Investimentos no Exterior: 3</li>
              <li>6. Investimentos em Imóveis: 1</li>
              <li>7. Gestão de Carteiras: 6</li>
              <li>8. Finanças Comportamentais: 4</li>
            </ul>
          </p>
          <p>O foco inicial dos estudos no módulo 2 devem ser os conteúdos <strong>4</strong>, <strong>5</strong>, <strong>6</strong> e <strong>8</strong>. A partir destes tópicos você atingirá pelo menos 15 acertos. É considerado “bom desempenho” nesta parte da matéria tão complexo e extensa. Após o domínio desses conteúdos você deverá seguir a seguinte ordem: Renda Fixa, Renda Variável, Gestão de Carteiras e Derivativos.</p>

          <p><strong>Recomendação: 75% (representa 19 acertos de 26 questões nas bíblias/simulado)</strong></p>
        </ReportText>

        <ReportBreakPage />

        <TitleModule>Módulo 3 – Planejamento de Aposentadoria</TitleModule>
        <ReportText type='description'>
          <p>É considerado um módulo com grau de dificuldade médio podendo conter entre 13 e 18 questões. Acreditamos que sua prova terá 16 (dezesseis) questões. Historicamente a prova tem cobrado principalmente a compreensão do candidato em <strong>PGBL</strong> & <strong>VGBL</strong>, <strong>Tributação progressiva & regressiva</strong>. Nos dois últimos exames, a Planejar cobrou conceitos sobre Benefício definido, contribuição definida e Contribuição Variável (aproximadamente quatro questões). </p>
          <p><strong>Recomendação: 85% (representa 14 acertos de 16 questões nas bíblias/simulado)</strong></p>
        </ReportText>

        <TitleModule>Módulo 4 – Gestão de Riscos e Seguros</TitleModule>
        <ReportText type='description'>
          <p>É considerado um módulo “médio” podendo conter entre 13 e 18 questões. Acreditamos que sua prova terá 14 (quatorze) questões. Nas últimas provas foi cobrado a <strong>aplicabilidade de seguros</strong>, assim como conceitos técnicos de cada produto. Portanto é de suma importância a leitura do material deste módulo.</p>
          <p><strong>Recomendação: 85% (representa 12 acertos de 14 questões nas bíblias/simulado)</strong></p>
        </ReportText>

        <ReportBreakPage />

        <TitleModule>Módulo 5 – Planejamento Fiscal</TitleModule>
        <ReportText type='description'>
          <p>É considerado um módulo com grau de dificuldade média/difícil podendo conter entre 11 e 17 questões. Acreditamos que sua prova terá 16 (dezesseis) questões. Historicamente, a prova tem cobrado principalmente tributação de produto (cerca de 35%), <strong>cálculo de Imposto de Renda Pessoa Física</strong> (tabela completa e simplificada) e <strong>tributação de investimentos no exterior </strong>(somente pessoas que moram no Brasil).</p>
          <p><strong>Recomendação: 80% (representa 13 acertos de 16 questões nas bíblias/simulado)</strong></p>
        </ReportText>

        <TitleModule>Módulo 6 – Planejamento Sucessório</TitleModule>
        <ReportText type='description'>
          <p>É considerado um módulo com grau de dificuldade média/difícil podendo conter entre 10 e 15 questões. Acreditamos que sua prova terá 14 (quatorze) questões. Historicamente a prova tem cobrado principalmente a <strong>distribuição da herança</strong>, conceito de <strong>herdeiros necessários </strong>e as doações com <strong>cláusulas restritivas</strong>.</p>
          <p>Após maio de 2022, a prova apresentou questões extremamente longas, e posteriormente voltou a sua estrutura histórica. Por serem as últimas  questões da parte da tarde, é importante prestar atenção redobrada na leitura, pois o candidato já estará cansado.</p>
          <p><strong>Recomendação: 75% (representa 11 acertos de 14 questões nas bíblias/simulado)</strong></p>
        </ReportText>

        <ReportBreakPage />

        <ReportText type="graph">Desempenho por aula</ReportText>
        <ReportText type='description'>
          <p>Foque nas aulas em <strong style={{ color: '#fcaf20', textDecoration: 'underline' }}>laranja</strong> para elevar ainda mais sua média em cada módulo.</p>
        </ReportText>

        <div style={{ marginBottom: '2vw' }}>
          <LegendChart
            displaySubtitle={'flex'}
            subtitleMin={'Laranja'}
            subtitleRecommended={'Lilás'}
            colorOne={'#F9DDC0'}
            colorTwo={'#C1C6F2'}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>
          {
            reportPerClass?.map((item) => {
              return (
                <>
                  <BarchartH
                    zIndex={0}
                    displaySubtitle={'none'}
                    displayAvaliable={"none"}
                    classNumber={5}
                    percentText={`${Math.round(item.porcentagem)}%`}
                    percent={Math.round(item.porcentagem)}
                    total={item.titulo}
                    fontSize={'1.2vw'}
                    positionPercent={'start'}
                    colorbk1={item.cor}
                    colorText={'#000000'}
                    description={"flex"}
                    textdescription={item.texto}
                    height={"2.258vw"}
                    widthGraph={"45%"}
                  />
                </>
              )
            })
          }
        </div>

        <ReportBreakPage />

        <div style={{ marginTop: '3vw' }}>

          <ReportText type="description" dangerouslySetInnerHTML={{ __html: suggestion?.replace('{name}', report?.name) }} />

          <FooterReport>
            <div className='logo-gold'>
              <img src={LogoGold} alt='logo' />
            </div>
            <div className='description-footer'>
              <p>Muito obrigado por escolher a Rafael Toro Academia de Finanças :)</p>
              <p>Um grande abraço dos professores e amigos Rafael Toro, Victório Santi e equipe ART.</p>
            </div>
          </FooterReport>
        </div>
      </Container>
    </>
  );
};
export default CfpReport;
