import request from '../../shared/request'

export function getQuestionByCode(questionCode) {
  return request(`/v1/services/questions/explained/${questionCode}`, {
    method: 'GET',
  })
}

export function getQuestionById(questionId) {
  return request(`/v1/services/questions/explainedById/${questionId}`, {
    method: 'GET',
  })
}

export function getQuestionsByStudent() {
  return request(`/v1/services/questions/find/studentV2`, {
    method: 'GET',
  })
}

export function getTestsToPrint(classId) {
  return request(`/v1/services/questions/print/${classId}`, {
    method: 'GET',
  })
}
