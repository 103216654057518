import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Spin } from 'antd'

import Main from '../pages/main'
import Auth from '../pages/auth'
import ProtectedRoute from '../utils/ProtectedRoute.component'

import MainCheckout from '../pages/Checkout/Main';
import ModuleCheckout from '../pages/Checkout/Module';
import CheckoutNotFound from '../pages/Checkout/NotFound';

import GabaritoHome from '../pages/gabarito/Home';
import GabaritoCreate from '../pages/gabarito/CreateTwo';
import GabaritoLogin from '../pages/gabarito/LoginTwo';
import GabaritoAnswers from '../pages/gabarito/StepAnswers';
import GabaritoAvailableResult from '../pages/gabarito/AvailableResult';
import ServiceTerms from '../pages/gabarito/ServiceTerms';
import StepsUnsubscriptionChannels from '../pages/gabarito/StepsUnsubscriptionChannels';

// Gabaritoro Modular
import GabaritoModuleLogin from '../pages/gabarito/Modular/Login';
import GabaritoModule from '../pages/gabarito/Modular/Create';
import GabaritoResendEmail from '../pages/gabarito/ResendEmail';

import gtm from '../shared/gtm';
import CfpSimuladoPdf from '../pages/CFP'
import cfpRelatorio from '../pages/CFP/Weekly/PDF/Class'
import CFPWeeklyPNG from '../pages/CFP/Weekly/PNG';

function AppRoutes() {
  const locations = useLocation()
  useEffect(() => {
    gtm(window.location.pathname);
  }, [locations])

  return (
    <React.Suspense fallback={<Spin tip="Loading..." />}>
      <Switch>
        <Route path="/descadastrar/:token" component={StepsUnsubscriptionChannels} />
        <Route path="/gabaritoro/reenviar-resultado" component={GabaritoResendEmail} />
        <Route path="/gabaritoro/descadastrar/:token" component={StepsUnsubscriptionChannels} />
        <Route path="/gabaritoro/termos-de-servico" component={ServiceTerms} />
        <Route path="/gabaritoro/result/:token" component={GabaritoAvailableResult} />
        <Route path="/gabaritoro/login" component={GabaritoLogin} />
        <Route path="/gabaritoro/respostas/:token/:exam?" component={GabaritoAnswers} />
        <Route path="/gabaritoro" component={GabaritoHome} exact />
        <Route path="/gabaritoro/create" component={GabaritoCreate} />

        <Route path="/cfp-simulado-pdf" component={CfpSimuladoPdf} />
        <Route path="/cfp-weekly-pdf" component={cfpRelatorio} />
        <Route path="/cfp-weekly-png" component={CFPWeeklyPNG} />

        <Route path="/gabaritoro-modular/login" component={GabaritoModuleLogin} />
        <Route path="/gabaritoro-modular" component={GabaritoModule} />

        <Route path="/checkout-notfound/" component={CheckoutNotFound} />
        <Route path="/checkout-modular/:curso/:id_turma/:afiliado?" component={ModuleCheckout} />
        <Route path="/newCheckout/:curso/:id_turma/:afiliado?" component={MainCheckout} />
        {/*<Route path="/newCheckout/:curso/:id_turma" component={Checkout} />*/}
        <Route path="/auth" component={Auth} />
        <ProtectedRoute path="/" component={Main} />
      </Switch>
    </React.Suspense>
  )
}

export default AppRoutes
