import React from 'react';
import { Col } from 'antd';
import ChartBar from '../../graphs/ChartBar';
import ChartBarPercent from '../../graphs/ChartBarPercent';

import {
  Header,
  Content,
  RowContent,
  Graphs,
  Line,
  ColM,
  ColBar,
} from './styles'
import TextsHeader from '../../TextsHeader';

function SimulatedPerModules(props) {
  const data = props.data;

  const mockSimulatedByModule = {
    simulado_1 :[
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "1",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "2",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "2",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "1",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "3",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "1",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "4",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "1",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "5",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "1",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "6",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "1",
        total: 0
      },
    ],
    // simulado_2 :[
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "1",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "2",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "2",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "2",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "3",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "2",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "4",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "2",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "5",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "2",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "6",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "2",
    //     total: 0
    //   },
    // ],
    simulado_3 :[
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "1",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "3",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "2",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "3",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "3",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "3",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "4",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "3",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "5",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "3",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "6",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "3",
        total: 0
      },
    ],
    // simulado_4 :[
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "1",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "4",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "2",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "4",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "3",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "4",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "4",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "4",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "5",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "4",
    //     total: 0
    //   },
    //   {
    //     acertos: 0,
    //     media_acertos: 0,
    //     media_total: 0,
    //     module: "6",
    //     nota_percentual: 0,
    //     porcentagem: 0,
    //     simulado: "4",
    //     total: 0
    //   },
    // ],
    simulado_5 :[
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "1",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "5",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "2",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "5",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "3",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "5",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "4",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "5",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "5",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "5",
        total: 0
      },
      {
        acertos: 0,
        media_acertos: 0,
        media_total: 0,
        module: "6",
        nota_percentual: 0,
        porcentagem: 0,
        simulado: "5",
        total: 0
      },
    ],
  }
  const simulatedModule = data?.simulated_by_module ?? [];

  Object.keys(mockSimulatedByModule).forEach((simulado) => {
    const modulos = mockSimulatedByModule[simulado];
    modulos.forEach((modulo) => {
      if (!!simulatedModule[simulado] && simulatedModule[simulado].filter(simuladoModulo => simuladoModulo.module === modulo.module).length) {
        mockSimulatedByModule[simulado][modulo.module - 1] = simulatedModule[simulado].filter(simuladoModulo => simuladoModulo.module === modulo.module)[0];
      }
    })
  })

  const completeNumbers = [0, 1, 2, 3, 4, 5];
  let modules = [];

  const simulado = mockSimulatedByModule;

  return (
    <>
      <TextsHeader>
        <p>
          Veja no detalhe o seu desempenho em cada Simulado. Se você realizou mais de uma vez algum dos simulados, vamos mostrar a média das rodadas em que você praticou. Segue comigo:
        </p>
      </TextsHeader>
      {
        Object.values(simulado)?.map((itens, i) => {
          modules = [0, 0, 0, 0, 0, 0]
          completeNumbers.map((_, x) => {
            if (Object.values(itens)[x] !== undefined) {
              modules[parseInt(Object.values(itens)[x].module) - 1] = Object.values(itens)[x];
            }
            return 0;
          });

          return (
            <>
              <Header>
                <h1>{Object.keys(simulado)[i].replace('_', ' ').replace('s', 'S')}</h1>
              </Header>

              <Content>
                <RowContent>
                  <Col xs={12}>
                    <Graphs>
                      {modules?.map((item) => {
                        return (
                          <>
                            {item === 0
                              ? (
                                <ChartBar progress={(0).toFixed(0)} />
                              ) : (item?.porcentagem <= 10
                                ? (
                                  <div style={{
                                    position: 'relative',
                                    marginTop: '-1vw',
                                    display: 'inline-block',
                                    height: '',
                                    color: 'white'
                                  }}>
                                    <ChartBar progress={(item?.porcentagem).toFixed(0)} />
                                  </div>
                                ) : (
                                  <ChartBar progress={(item?.porcentagem).toFixed(0)} />
                                )
                              )
                            }
                          </>
                        )
                      })
                      }
                    </Graphs>
                    <Line />
                    <ColM>
                      {
                        completeNumbers.map((item => {
                          return <h3>M{item+1}</h3>
                        }))
                      }
                    </ColM>
                  </Col>

                  <ColBar xs={10}>
                    {itens?.map((item) => {
                      return (
                        <>
                          {
                            <ChartBarPercent
                              percentModulo={(item?.acertos / item?.total * 100).toFixed() }
                              modulo={item?.module}
                              total={`${item?.acertos}/${item?.total} acertos`}
                            />
                          }
                        </>
                      )
                    })}
                  </ColBar>
                </RowContent>
              </Content>
            </>
          )
        })}

    </>

  );
};

export default SimulatedPerModules;
