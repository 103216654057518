import React from 'react'
import CacheBuster from 'react-cache-buster';
import { Skeleton } from 'antd';
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'

import { version } from '../package.json';
import history from './history'
import * as Sentry from '@sentry/react'
import AppRoutes from './routes/AppRoutes'

import { ThemeProvider } from 'styled-components';
import theme from './shared/styled/theme';

import './styles/main.scss'

import { TimerProvider } from './hooks/useTimer';

history.listen((location) => {
  Sentry.setTag('path', location)
})

function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<Skeleton active />} //If not pass, nothing appears at the time of new version check.
    >
      <Provider store={store}>
        <TimerProvider>
          <ThemeProvider theme={theme}>
            <PersistGate loading={null} persistor={persistor}>
              <Router history={history}>
                <AppRoutes />
              </Router>
            </PersistGate>
          </ThemeProvider>
        </TimerProvider>
      </Provider>
    </CacheBuster>
  )
}

export default App
