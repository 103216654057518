import React, { useCallback, useEffect, useRef, useState } from 'react';

import LectureQuestionReview from './LectureQuestionReview.component';
import LectureQuestion from './LectureQuestion.component';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { TextField } from '../Form';
import { connect } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import { Col, Form, Modal, Row, Table } from 'antd';

import { actions as questionActions } from '../../redux/modules/question';

import * as questionSelectors from '../../redux/selectors/question';

import { useHistory, useLocation } from 'react-router-dom';

const proptypes = {
  loading: PropTypes.bool.isRequired, 
  getQuestionById: PropTypes.func.isRequired,
  getQuestionsByStudent: PropTypes.func.isRequired,
  setShowSearchQuestionModal: PropTypes.func.isRequired,
};

function SearchQuestionModal(props) {
  const location = useLocation()
  const history = useHistory()

  const {
    getQuestionsByStudent,
    getQuestionById, loading,
    setShowSearchQuestionModal,
  } = props
  
  const formRef = useRef()

  const FILTER_OBJ = {
    codigo: '',
    enunciado: '',
  };

  const [chosen, setChosen] = useState({});
  const [isVisible, setIsVisible] = useState(true);
  const [filters, setFilters] = useState(FILTER_OBJ);
  const [startReview, setStartReview] = useState(false);
  const [startQuestion, setStartQuestion] = useState(false);
  const [allQuestions, setAllQuestions] = useState([FILTER_OBJ]);
  const [questionData, setQuestionData] = useState({ awnsers: [] });
  const [filteredQuestion, setFilteredQuestions] = useState([FILTER_OBJ]);
  const [ LSBVideo, setLSBVideo] = useState(false);
  const [ modalVideoOption, setModalVideoOption] = useState(false);
  


  useEffect(() => {
    getQuestionsByStudent((questions) => {
      setAllQuestions(questions);
      setFilteredQuestions(questions);
    });
  }, [getQuestionsByStudent, questionData]);

  const filterQuestions = useCallback(() => {
    const { codigo: cod, enunciado: enun } = filters;
    let filteredQuestions = {};
    if (cod && enun) {
      filteredQuestions = allQuestions.filter(
        ({ enunciado, codigo }) =>
          enunciado.toLowerCase().includes(enun.toLowerCase()) &&
          codigo.match(new RegExp(`^${cod}`, 'gi'))
      );
      setFilteredQuestions(filteredQuestions);
    } else if (cod) {
      filteredQuestions = allQuestions.filter(({ codigo }) =>
        codigo.match(RegExp(`^${cod}`, 'gi'))
      );
      setFilteredQuestions(filteredQuestions);
    } else if (enun) {
      filteredQuestions = allQuestions.filter(({ enunciado }) =>
        enunciado.toLowerCase().includes(enun.toLowerCase())
      );
      setFilteredQuestions(filteredQuestions);
    } else {
      setFilteredQuestions(allQuestions);
    }
  }, [filters, allQuestions]);

  useEffect(() => {
    filterQuestions();
  }, [filterQuestions, chosen]);

  const closeModal = () => {
    setIsVisible(false);
    setShowSearchQuestionModal(false);
    const { pathname } = location
    history.push(pathname)
  };

  const handleChangeFilters = ({ target }) => {
    const { value, name } = target;
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectedQuestion = (questionCode) => {
    getQuestionById(questionCode, questionSeleceted => {
      setQuestionData(questionSeleceted)
    })
  }
  
  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      render: (text) => {
        const obj = {
          displayName: 'Código',
          children: <p className="modal-search-row">{text}</p>,
          props: {},
        };

        return obj;
      },
    },
    {
      title: 'Enunciado',
      dataIndex: 'enunciado',
      key: 'enunciado',
      render: (text) => {
        const obj = {
          displayName: 'Enunciado',
          children: <p className="search-row">{text}</p>,
          props: {},
        };

        return obj;
      },
    },
  ];

  const setChanges = (handleChange, e, value) => {
    handleChange(e);
    handleChangeFilters(e, value);
  };

  const handleModalQuestion = (idQuestion) => {
    handleSelectedQuestion(idQuestion);
    setStartQuestion(true);
  };

  return (
    <>
      <Modal
        closable
        width={960}
        maskClosable
        zIndex="1000"
        footer={null}
        destroyOnClose
        title="Questões"
        visible={isVisible}
        onCancel={() => closeModal()}
      >
        <div className="modal-body">
          <Formik initialValues={FILTER_OBJ} innerRef={formRef}>
            {({ values, handleChange }) => (
              <Form>
                <Row gutter={[4, 20]} style={{ marginTop: '10px' }}>
                  <Col span={22} offset={1}>
                    <Text>
                      Pesquise pelo código ou enunciado de questões específicas que você deseja
                      refazer. Suas respostas não serão salvas no banco de dados e não influenciarão
                      nos seus relatórios.
                    </Text>
                  </Col>
                </Row>
                <Row gutter={10} align="middle">
                  <Col span={6} offset={1}>
                    <Text strong>Código</Text>
                    <TextField
                      name="codigo"
                      value={values.codigo}
                      onChange={(e) => setChanges(handleChange, e, values.codigo)}
                    />
                  </Col>
                  <Col span={12}>
                    <Text level={5} strong>
                      Enunciado
                    </Text>
                    <TextField
                      name="enunciado"
                      value={values.enunciado}
                      onChange={(e) => setChanges(handleChange, e, values.enunciado)}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col offset={1} span={22}>
                    <Table
                      pagination={{ defaultPageSize: 15, showSizeChanger: false }}
                      dataSource={filteredQuestion}
                      columns={columns}
                      scroll={{ x: '50vw' }}
                      loading={loading}
                      size="small"
                      onRow={(record) => {
                        const { id_questao } = record;
                        return {
                          onClick: () => handleModalQuestion(id_questao),
                        };
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      {startQuestion && (
        <LectureQuestion
          chosen={chosen}
          setChosen={setChosen}
          questionData={questionData}
          startQuestion={startQuestion}
          setStartReview={setStartReview}
          setQuestionData={setQuestionData}
          setStartQuestion={setStartQuestion}
          LSBVideo={LSBVideo}
          modalVideoOption={modalVideoOption}
          setLSBVideo={setLSBVideo}
          setModalVideoOption={setModalVideoOption}
        />
      )}
      {startReview && (
        <LectureQuestionReview
          chosen={chosen}
          startReview={startReview}
          questionData={questionData}
          setStartReview={setStartReview}
          setLSBVideo={setLSBVideo}
          setModalVideoOption={setModalVideoOption}
        />
      )}
    </>
  );
}

SearchQuestionModal.propTypes = proptypes;

const mapStateToProps = (state, props) => ({
  loading: questionSelectors.isLoading(state, props),
});
const mapDispatchToProps = (dispatch) => ({
  getQuestionById: (questionId, next) =>
    dispatch(questionActions.getQuestionById(questionId, next)),
  getQuestionsByStudent: (aluno_id, next) =>
    dispatch(questionActions.getQuestionsByStudent(aluno_id, next)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchQuestionModal);
