import { all, fork, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { constants } from '../modules/question'
import * as api from '../api/question'

// QUESTION_GET_QUESTION_BY_CODE
function* getQuestionByCode(action) {
    try {
      const payload = yield call(api.getQuestionByCode, action.questionCode)
      yield put({
        type: constants.QUESTION_GET_QUESTION_BY_CODE.SUCCESS,
        payload
      })
      action.next && action.next(payload)
    } catch (e) {
      yield put({
        type: constants.QUESTION_GET_QUESTION_BY_CODE.FAILED,
        message: e.message || e,
      })
      notification['error']({
        message: `Problemas ao listar a questão comentada, por favor contate o suporte.`,
      })
    }
  }

function* getQuestionById(action) {
  try {
    const payload = yield call(api.getQuestionById, action.questionId)
    yield put({
      type: constants.QUESTION_BY_ID.SUCCESS,
      payload
    })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_BY_ID.FAILED,
      message: e.message || e,
    })
    notification['error']({
      message: `Problemas ao listar a questão comentada, por favor contate o suporte.`,
    })
  }
}


function* getQuestionsByStudent(action) {
  try {
    const payload = yield call(api.getQuestionsByStudent)
    yield put({
      type: constants.QUESTION_BY_STUDENT.SUCCESS,
      payload
    })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_BY_STUDENT.FAILED,
      message: e.message || e,
    })
    notification['error']({
      message: `Problemas ao listar suas questões, por favor contate o suporte.`,
    })
  }
}

function* watchGetQuestionByCode() {
  yield takeEvery(constants.QUESTION_GET_QUESTION_BY_CODE.ACTION, getQuestionByCode)
}

function* watchGetQuestionByStudent() {
  yield takeEvery(constants.QUESTION_BY_STUDENT.ACTION, getQuestionsByStudent)
}


function* watchGetQuestionById() {
  yield takeEvery(constants.QUESTION_BY_ID.ACTION, getQuestionById)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetQuestionByCode),
    fork(watchGetQuestionByStudent),
    fork(watchGetQuestionById)
  ])
}
