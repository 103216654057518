import React, { useState, useEffect } from 'react'

import { CheckOutlined, CalculatorOutlined, YoutubeOutlined, AlignLeftOutlined } from '@ant-design/icons'
import { Button, Modal, Radio, Skeleton, Popconfirm, Tooltip } from 'antd'

import lectureIcon from '../../img/lecture-icon.png'
import FormulaMatematica from '../../pages/course/components/class/lecture/components/formula'
import LectureVideoQuestion from '../../pages/course/components/class/lecture/LectureVideoQuestion.component'
import LectureTextQuestion from '../../pages/course/components/class/lecture/LectureTextQuestion.component'

import PropTypes from 'prop-types'

const propTypes = {
  chosen: PropTypes.number.isRequired,
  setChosen: PropTypes.func.isRequired,
  startQuestion: PropTypes.bool.isRequired,
  questionData: PropTypes.object.isRequired,
  setStartReview: PropTypes.func.isRequired,
  setQuestionData: PropTypes.func.isRequired,
  setStartQuestion: PropTypes.func.isRequired,
  setLSBVideo: PropTypes.func,
  setModalVideoOption: PropTypes.func
}

function LectureQuestion(props) {
  const {
    startQuestion,
    setStartQuestion,
    questionData,
    setChosen,
    chosen,
    setStartReview,
    setQuestionData,
    setLSBVideo,
    setModalVideoOption
  } = props

  const [marked, setMarked] = useState(true)
  const [formulaVisible, setFormulaVisible] = useState(false)
  const [questionVideoStart, setQuestionVideoStart] = useState(false)
  const [questionTextShow, setQuestionTextShow] = useState(false)

  if (questionData.awnsers.length === 4) {
    const naoSei = {
      id_resposta: 'idk',
      id_questao: questionData.id_questao,
      correta: 0,
      resposta: 'Não sei',
    }
    questionData.awnsers.push(naoSei)
    setQuestionData(questionData)
  }

  useEffect(() => {}, [chosen])

  const formulaMtematica = () => {
    return (
      <Button onClick={() => setFormulaVisible(true)}>
        <CalculatorOutlined /> Dica da questão
      </Button>
    )
  }

  const handleReview = () => {
    setStartReview(true)
    setStartQuestion(false)
  }

  const renderFooter = () => {
    return (
      <>
        {(questionData.formula || questionData.formula_imagem) && formulaMtematica()}
        {questionData.questao_comentada && 
          <Button onClick={() => setQuestionTextShow(true)} className="commented-question">
            Ler a resolução <AlignLeftOutlined style={{ fontSize: 20 }} />
          </Button>
        }
        {questionData.video_embed && 
          <Button onClick={() => setQuestionVideoStart(true)} className="commented-question">
            Assistir a resolução <YoutubeOutlined style={{ fontSize: 20 }} />
          </Button>
        }
        <Tooltip color={'orange'} placement="top" title={marked ? 'Marque uma opção' : ''}>
          <Popconfirm
            okText="Sim"
            cancelText="Não"
            disabled={marked}
            onConfirm={() => handleReview()}
            title="Você tem certeza que deseja FINALIZAR?"
            icon={<CheckOutlined style={{ color: 'green' }} />}
          >
            <Button className="btnFinishLecture">Salvar e ver resultado</Button>
          </Popconfirm>
        </Tooltip>
      </>
    )
  }

  const renderImg = (img) => {
    if (img) {
      const domain = 'https://arquivos.academiarafaeltoro.com.br'
      const path = 'uploads/'
      const imgWithPath = img.indexOf(path) >= 0 ? img : path + img
      return <img src={`${domain}/${imgWithPath}`} alt="" style={{ marginTop: 10 }} />
    }
    return ''
  }

  const setQuestionAnswer = (answer) => {
    setChosen({ [questionData.id_questao]: answer })
    questionData.awnsers.map((el) => {
      if (el.id_resposta === answer) {
        el.marcada = true
      } else {
        el.marcada = false
      }
      return true
    })
    setMarked(false)
  }

  const radioStyle = {
    alignItems: 'baseline',
    display: 'flex',
    marginBottom: 15,
    lineHeight: '30px',
    whiteSpace: 'break-spaces',
  }

  return (
    <Modal
      width={932}
      zIndex="1001"
      closable={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={startQuestion}
      footer={renderFooter()}
      wrapClassName="lectureContainer"
      onCancel={() => setStartQuestion(false)}
    >
      <div className="modal-body">
        {questionData.video_embed && (
          <LectureVideoQuestion
            videoEmbed={questionData.video_embed}
            questionVideoStart={questionVideoStart}
            setQuestionVideoStart={setQuestionVideoStart}
            setLSBVideo={setLSBVideo}
            setModalVideoOption={setModalVideoOption}
          />
        )}
        {questionData.questao_comentada && (
          <LectureTextQuestion
            text={questionData.questao_comentada}
            questionTextShow={questionTextShow}
            setQuestionTextShow={setQuestionTextShow}
          />
        )}
        <div className="lecture-icon">
          <img src={lectureIcon} alt={'Icone de Aula'} />
        </div>
        <header>
          {!questionData ? (
            <Skeleton active />
          ) : (
            <>
              <h1>Questão {questionData.codigo}</h1>
              <p style={{ whiteSpace: 'pre-wrap' }}>
                ({questionData.codigo}) {questionData.enunciado}
                {renderImg(questionData.img_enunciado)}
              </p>
            </>
          )}
        </header>
        <div className="questionAnswersContainer">
          {questionData.answers ? (
            <Skeleton active />
          ) : (
            <>
              <Radio.Group onChange={(e) => setQuestionAnswer(e.target.value)}>
                {questionData.awnsers
                  ?.filter((answer) => answer.resposta)
                  ?.map((answer, index) => {
                    return (
                      <Radio key={`answer-${index}`} style={radioStyle} value={answer.id_resposta}>
                        {answer.resposta}
                      </Radio>
                    )
                  })}
              </Radio.Group>
              {formulaVisible ? (
                <FormulaMatematica
                  formulaVisible={formulaVisible}
                  currentQuestion={questionData}
                  setFormulaVisible={setFormulaVisible}
                />
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

LectureQuestion.propTypes = propTypes

export default LectureQuestion
