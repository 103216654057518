import React, { useState, useContext } from 'react';

import { CourseContext } from '../../pages/course/ClassContent/CourseDetail.container';

import { PlayCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, AlignLeftOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import LectureVideoQuestion from '../../pages/course/components/class/lecture/LectureVideoQuestion.component';
import LectureTextQuestion from '../../pages/course/components/class/lecture/LectureTextQuestion.component';

import PropTypes from 'prop-types';

const propTypes = {
  chosen: PropTypes.object.isRequired,
  startReview: PropTypes.bool.isRequired,
  questionData: PropTypes.object.isRequired,
  setStartReview: PropTypes.func.isRequired,
  setLSBVideo: PropTypes.func.isRequired,
  setModalVideoOption: PropTypes.func.isRequired,
};

function LectureQuestionReview({
  startReview,
  setStartReview,
  chosen,
  questionData,
  setLSBVideo,
  setModalVideoOption,
}) {
  const { setClassStep } = useContext(CourseContext);

  const [videoToShow, setVideoToShow] = useState();
  const [questionVideoStart, setQuestionVideoStart] = useState(false);
  const [questionTextShow, setQuestionTextShow] = useState(false);

  const renderImg = (img) => {
    if (img) {
      const domain = 'https://arquivos.academiarafaeltoro.com.br';
      const path = 'uploads/';
      const imgWithPath = img.indexOf(path) >= 0 ? img : path + img;
      return <img src={`${domain}/${imgWithPath}`} alt="" style={{ marginTop: 10 }} />;
    }
    return '';
  };

  const renderAnswerIcon = (answer) => {
    if ((answer.marcada && answer.correta === 1) || answer.correta === 1) {
      return <CheckCircleOutlined style={{ fontSize: 36, marginRight: 10, marginLeft: 10 }} />;
    } else if (answer.correta !== 1 && answer.marcada) {
      return (
        <CloseCircleOutlined
          style={{ fontSize: 36, color: '#DE0000', marginRight: 10, marginLeft: 10 }}
        />
      );
    }
  };

  const handleStartQuestionVideo = (videoEmbed) => {
    setVideoToShow(videoEmbed);
    setQuestionVideoStart(true);
  };

  function handleCancel() {
    if (setClassStep) {
      setClassStep('lecture');
    }

    setStartReview(false);
  }

  const alphabeticalOrder = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];

  return (
    <Modal
      width={932}
      footer={false}
      zIndex={10000}
      closable={true}
      maskClosable={true}
      destroyOnClose={true}
      visible={startReview}
      title="Revisão da prova"
      onCancel={() => handleCancel()}
      wrapClassName="lectureReviewContainer"
    >
      <div className="modal-body">
        {questionData.video_embed && (
          <LectureVideoQuestion
            videoEmbed={videoToShow}
            questionVideoStart={questionVideoStart}
            setQuestionVideoStart={setQuestionVideoStart}
            setLSBVideo={setLSBVideo}
            setModalVideoOption={setModalVideoOption}
          />
        )}
        {questionData.questao_comentada && (
          <LectureTextQuestion
            text={questionData.questao_comentada}
            questionTextShow={questionTextShow}
            setQuestionTextShow={setQuestionTextShow}
          />
        )}
        <div className="questionAnswersContainer">
          <ul>
            <div>
              <div className="questionDescription">
                <h1>Questão {questionData.codigo}</h1>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  ({questionData.codigo}) {questionData.enunciado}{' '}
                  {renderImg(questionData.img_enunciado)}
                </p>
              </div>
              <div className="questionVideoContainer">
                <Button className='questionTextButton' onClick={() => setQuestionTextShow(true)}>
                  <AlignLeftOutlined style={{ fontSize: 20 }} />
                  Ler a resolução
                </Button>
                <Button onClick={() => handleStartQuestionVideo(questionData.video_embed)}>
                  <PlayCircleOutlined style={{ fontSize: 20 }} />
                  Assistir a resolução
                </Button>
              </div>
            </div>
            {questionData.awnsers.map((answer, index) => {
              const answered = questionData?.awnsers.find(
                (el) => +el.id_resposta === +chosen[el.id_questao] && el.correta !== 0
              );
              return (
                <li
                  key={`answer-${index}`}
                  className={
                    answer.correta === 1
                      ? 'correta'
                      : chosen[answer.id_questao] === answer.id_resposta
                      ? 'selected'
                      : ''
                  }
                >
                  {`${alphabeticalOrder[index]}. `}
                  {answer.resposta}
                  {renderAnswerIcon(answer, answered)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Modal>
  );
}

LectureQuestionReview.propTypes = propTypes;

export default LectureQuestionReview;
