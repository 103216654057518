import React from 'react'
import PropTypes from 'prop-types'
import { Form as AntForm } from 'antd'

const propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.node.isRequired,
}

const Form = ({
  children,
  onSubmit = () => {
  },
  onError = () => {
  },
}) => {
  return (
    <AntForm
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onSubmit}
      onFinishFailed={onError}
    >
      {children}
    </AntForm>
  )
}

Form.propTypes = propTypes

export default Form
