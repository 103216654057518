import React, { useEffect } from 'react';
import LogoIcon from '../../img/svg/logo-icon.svg'
import Logo from '../../img/svg/logo-white.svg'

import {
  Container,
  Header,
  Img,
  NameRow,
  ImgLogo,
  PageOne,
} from './styles'
import TextsHeader from './TextsHeader';
import StaticGraph from './graphs/staticGraph';
import MediaPonderada from './mediaPonderada';
import GeralResults from './GeralResults';
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { actions as studentActions } from '../../redux/modules/student';
import * as studentSelectors from '../../redux/selectors/student'
import { FooterReport } from './Weekly/PDF/Class/styles';
import ReportText from './Report/TextDescription';
import {
  TYPE_COURSE_CATEGORY_CFP,
  TYPE_SCORE_INFORMATION_REPORT_PDF,
  TYPE_SCORE_INFORMATION_REPORT_SIMULATED,
  getSusgestionByScoreToro
} from './common';
import LogoGold from '../../img/logo-gold.svg'
import SimulatedPerModules from './Report/SimulatedPerModules';
import { isEmpty } from 'lodash';
import { fetchGeneralText } from '../../redux/api/class';


//http://localhost:3000/cfp-simulado-pdf/cfp?user_id=7991&course_id=180&class_id=134&media=pdf&start=2022-04-10&end=2022-05-30&kind=simulado

const propTypes = {
  handleCfpSimulado: PropTypes.func.isRequired,
  isFetchCfpsimulado: PropTypes.bool.isRequired
}

function CfpSimuladoPdf({ handleCfpSimulado, isFetchCfpsimulado }) {
    const [textData, setTextData] = React.useState('')

    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('user_id')
    const courseId = urlParams.get('course_id')
    const classId = urlParams.get('class_id')
    const start = urlParams.get('start')
    const media = urlParams.get('media')
    const end = urlParams.get('end')
    const kind = urlParams.get('kind')
    const classes = urlParams.get('classes')

    const { reportSimulado, scoreToroInformation } = useSelector((state) => state.student);
    const suggestion = getSusgestionByScoreToro(scoreToroInformation, Number(((reportSimulado?.average?.acertos_avg ?? 0) * 100).toFixed(1)))
    const dispatch = useDispatch();

    useEffect(() => {
        handleCfpSimulado(userId, courseId, classId, media, start, end, kind, classes)

        dispatch(studentActions.getScoreToroSugestionsByTypes(
          TYPE_COURSE_CATEGORY_CFP,
          TYPE_SCORE_INFORMATION_REPORT_PDF,
          TYPE_SCORE_INFORMATION_REPORT_SIMULATED
        ));
        // eslint-disable-next-line
    }, [])

    useEffect(()=>{
      (async ()=>{
        const filters = {
          typeReportId: TYPE_COURSE_CATEGORY_CFP,
          categoryReportId: TYPE_SCORE_INFORMATION_REPORT_SIMULATED
        }
        const textDataApi = await fetchGeneralText(filters)
        if(textDataApi[0]?.content !== undefined){
          setTextData(textDataApi[0]?.content)
        }
      })()
    },[])

    const isReportSimuladoEmpty = isEmpty(reportSimulado);

    const done = isReportSimuladoEmpty || isEmpty(reportSimulado?.test) ? 0 : reportSimulado?.test?.done[0]?.prova ?? 0;
    const toBeDone = isReportSimuladoEmpty || isEmpty(reportSimulado?.test) ? 30 : reportSimulado?.test?.to_be_done[0]?.prova ?? 0;

  return (
    <>
      {isFetchCfpsimulado || reportSimulado === null ? ('...carregando') : (
        <>
          <Header>
            <Img src={Logo} alt="logo" />
          </Header>

          <Container>
            <PageOne>
              <NameRow>
                <h1>Olá {reportSimulado?.name} :)</h1>
                <ImgLogo src={LogoIcon} alt="logo" />
              </NameRow>

              <TextsHeader>
                <div dangerouslySetInnerHTML={{ __html: textData.replace("#done#", done).replace("#toBeDone#", toBeDone) }} />
              </TextsHeader>

              <StaticGraph />

              <MediaPonderada data={reportSimulado} />
            </PageOne>
            <div className='reportContainer'>
              <SimulatedPerModules data={reportSimulado} />
              <GeralResults data={reportSimulado} />

              <div style={{ marginTop: '3vw' }}>
              <ReportText type="description" dangerouslySetInnerHTML={{ __html: suggestion?.replace('{name}', reportSimulado?.name) }} />
                <FooterReport>
                  <div className='logo-gold'>
                    <img src={LogoGold} alt='logo' />
                  </div>
                  <div className='description-footer'>
                    <p>Muito obrigado por escolher a Rafael Toro Academia de Finanças :)</p>
                    <p>Um grande abraço dos professores e amigos Rafael Toro, Victório Santi e equipe ART.</p>
                  </div>
                </FooterReport>
              </div>
            </div>
          </Container>
        </>
      )}
    </>

  );
};

const mapStateToProps = (state, props) => {
  return {
    student: studentSelectors.getCfpSimulado(state, props),
    isFetchCfpsimulado: studentSelectors.isFetchCfpsimulado(state, props),
  }
}

const mapDispatchToProps = dispatch => ({
    handleCfpSimulado: (userId, courseId, classId, media, start, end, kind, classes) => (
        dispatch(studentActions.handleCfpSimulado(userId, courseId, classId, media, start, end, kind, classes))
    )
})

CfpSimuladoPdf.propTypes = propTypes
export default connect(mapStateToProps, mapDispatchToProps)(CfpSimuladoPdf)
