import React from 'react'

import { Form as AntForm, Input } from 'antd'

const PasswordField = ({ label, name, errorMessage, onChange, okIcon, onKeyPress, onBlur}) => (
  <AntForm.Item
    label={label}
    validateStatus={errorMessage ? 'error' : okIcon ? 'success' : false }
    help={errorMessage}
    hasFeedback
  >
    <Input.Password name={name} onChange={onChange} onKeyPress={onKeyPress} onBlur={onBlur}  />
  </AntForm.Item>
)

export default PasswordField
