import moment from 'moment';
import config from '../../config';

export default (courses) => {
  const cfp60DiasTag = [
    'cfp-60',
    'cfp2021_1turma',
    'cfp2021_2turma',
    'cfp2021_4turma',
    'cfp2021_xp',
    'turma_cfp_alunos',
    'cfp-2021',
    'cfp2021_agosto',
    'cfp2021_agosto_alunos',
    'cfp2021_agosto_turma-0',
    'cfp2021_agosto_turma-4',
    'cfp-novembro-21',
    'cfp-teste-smart',
    'cfp-teste-smart_1',
  ];

  const canShowCourse = () => {
    const today = new Date();
    let validation = false;
    courses.some((el) => {
      const validateDate = moment(el.data_fim_aulas).isBefore(today);
      return (validation = cfp60DiasTag.includes(el.tag) && validateDate);
    });
    return validation;
  };

  const canVisibleGabaritoro = () => {
    const isCFP = courses.filter(course => course.id_course_category === 1);
    return isCFP.length > 0;
  };

  return [
    {
      name: 'start',
      title: 'Início',
      link: true,
      show: false,
    },
    {
      name: 'courses',
      title: 'Meus cursos',
      link: true,
    },
    {
      name: 'user',
      title: 'Meu perfil',
      link: true,
    },
    {
      name: 'mentorship',
      title: 'Mentorias',
      show: false && courses && canShowCourse(),
    },
    {
      name: 'questions',
      title: 'Questões',
    },
    {
      name: 'podcast',
      title: 'Podcast',
      link: true,
    },
    {
      name: 'cashback',
      title: 'Carteira',
      link: true,
    },
    {
      name: 'gabaritoro',
      title: 'Gabaritoro',
      openURL: '/gabaritoro/create',
      show: canVisibleGabaritoro(),
    },
    /* {
      name: 'notifications',
      title: 'Notificações',
      openURL: '#', // TO DO: página notificações 
      border: true,
      totalNotifications: 3,
    }, */
    {
      name: 'support',
      title: 'Suporte',
      openURL: config.REACT_APP_SUPPORT_URL,
      border: true,
    },
    {
      name: 'faq',
      title: 'FAQ',
      openURL: config.REACT_APP_FAQ_URL,
    },
    {
      name: 'logout',
      title: 'Sair',
    },
    {
      name: 'oldStudent',
      title: 'Área do aluno antigo',
      show: false,
    },
  ];
};
